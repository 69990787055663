import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useRouter } from 'next/router';
import DatePicker from '@/components/elements/date-picker';
import BoatTypeSelect from '@/components/search/form/boat-type-select';
import Checkbox from '@/components/elements/checkbox';
import moment from 'moment/moment';
import SearchFormRegion from '../search/form/region';
import Button from '../elements/button';
import SearchFormDuration from '../search/form/duration';
import appConfig from '../../../app.config';
import defaultDate from '../../utils/defaultDate';

export default function SearchLine({
  sd, country, region, locationId, boatTypeId, setSd, setCountry, setRegion, setLocationId, headline, adacDefault = 0,
}) {
  const router = useRouter();

  const [startDate, setStartDate] = useState(null);

  const {
    clearErrors, formState: { errors }, handleSubmit, register, getValues, setValue,
  } = useForm({
    defaultValues: {
      sd: '',
      country: '',
      region_id: '',
      location_id: '',
      date_from: defaultDate(),
      date_to: '',
      duration: 7,
      adac: adacDefault,
      boat_type_id: boatTypeId,
      channel: appConfig.channel,
    },
  });

  register('country', { required: 'Bitte wählen Sie ein Fahrgebiet aus.' });

  const onSubmit = (data) => {
    const query = {
      ...data,
    };

    if (data.date_from !== '' || moment(data.date_from).isValid()) {
      query.date_to = moment(data.date_from).add(3, 'weeks').format('YYYY-MM-DD');
    }

    router.push({
      pathname: appConfig.primarySearchPage,
      query,
    });
  };

  return (
    <div className="p-5">
      <span className="block font-bold text-lg text-white md:mb-2 px-2 py-1 md:p-0 bg-primary-700 bg-opacity-80 md:bg-transparent">{headline}</span>
      <form onSubmit={handleSubmit(onSubmit)} className="grid md:grid-cols-8 lg:grid-cols-12 items-center bg-action p-1 gap-1">
        <div className="md:col-span-8 lg:col-span-4">
          <SearchFormRegion
            form={getValues()}
            setValue={(v) => {
              setValue('sd', v);
              setSd(v);
            }}
            sd={sd}
            errors={errors}
            country={country}
            region={region}
            locationId={locationId}
            interacted={() => clearErrors('country')}
            setCountry={(v) => {
              setValue('country', v?.country_id || '');
              setCountry(v);
            }}
            setRegion={(v) => {
              setValue('region_id', v?.region_id || '');
              setRegion(v);
            }}
            setLocationId={(v) => {
              setValue('location_id', v || '');
              setLocationId(v);
            }}
            next={() => {
            }}
          />
        </div>
        <div className="md:col-span-2">
          <DatePicker
            inputDate={startDate}
            setValue={setValue}
            onDateChange={setStartDate}
          />
        </div>
        <div className="md:col-span-2">
          <SearchFormDuration
            value={getValues('duration')}
            setValue={(field, value) => setValue(field, value)}
            next={() => {
            }}
          />
        </div>
        <div className="md:col-span-2">
          <BoatTypeSelect
            value={getValues('boat_type_id')}
            setValue={(field, value) => setValue(field, value)}
          />
        </div>
        <div className="md:col-span-2">
          <Button
            variant="primary"
            width="w-full"
            type="submit"
            style={{ maxHeight: '50px' }}
          >
            Suchen
          </Button>
        </div>

      </form>
      <div className="sm:col-span-12 mt-2 ">
        <Checkbox
          value={getValues('adac')}
          label={(
            <span>
              Ich bin
              {' '}
              <b>ADAC Mitglied</b>
            </span>
          )}
          name="adac"
          setValue={(v) => {
            setValue('adac', v);
          }}
        />
      </div>
    </div>
  );
}
